define('incus-vastaanota/services/session', ['exports', 'ember'], function (exports, _ember) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  exports['default'] = _ember['default'].Service.extend({
    user: null,
    showItems: null,
    showChat: false,
    apixclient: _ember['default'].inject.service(),
    saldoupdater: _ember['default'].inject.service(),
    laskumappiSkin: null,
    adminSession: null,
    saldo: 0,
    isInvoicingCustomer: false,
    routing: _ember['default'].inject.service('-routing'),
    redirection: null,
    fetchingSession: false,

    init: function init() {
      var _this = this;

      this._super();
      window.localStorageAlias = window.localStorage;
      if (!window.localStorage) {
        window.localStorageAlias = {};
        window.localStorageAlias.removeItem = function () {};
        window.localStorageAlias.getItem = function (item) {
          return this[item];
        };
        window.localStorageAlias.setItem = function (item, value) {
          this[item] = value;
        };
      }

      try {
        var user = window.localStorageAlias.getItem("session");
        if (user) {
          this.set("fetchingSession", true);
          user = JSON.parse(user);
          if (!user.TransferID || !user.TransferKey) {
            this.logout();
          }
          this.get("apixclient").getSession(user.TransferID, user.TransferKey).then(function (res) {
            var subuserIdx = -1;
            if (Array.isArray(res.data)) {
              subuserIdx = res.data.findIndex(function (obj) {
                return obj.hasOwnProperty("idSubUser");
              });
            }
            if (subuserIdx > -1) {
              (function () {
                var SubuserData = res.data.splice(subuserIdx, 1)[0];
                Object.keys(SubuserData).forEach(function (k) {
                  if (SubuserData[k] === "true") {
                    SubuserData[k] = true;
                  } else if (SubuserData[k] === "false") {
                    SubuserData[k] = false;
                  }
                });
                res.data[0].SubuserData = SubuserData;
              })();
            }

            var adminSession = window.localStorageAlias.getItem("adminSession");

            if (adminSession) {
              _this.login(res, JSON.parse(adminSession)).then(function (moveTo) {
                var args = [];
                var redirection = _this.get("redirection");
                if (redirection) {
                  args.push(redirection.destination);
                  if (Object.keys(redirection.path).length > 0) {
                    args.push([redirection.path[Object.keys(redirection.path)[0]]]);
                  }
                  if (Object.keys(redirection.queryParams).length > 0) {
                    args.push(_extends({}, redirection.queryParams));
                  }
                  if (redirection.destination !== "login") {
                    var _get;

                    (_get = _this.get("routing")).transitionTo.apply(_get, args);
                    return;
                  }
                }

                _this.get("routing").transitionTo(moveTo);
              })['catch'](function (error) {
                console.log(error);
                _this.logout();
              });
            } else {
              _this.login(res, null).then(function (moveTo) {
                var args = [];
                var redirection = _this.get("redirection");
                if (redirection) {
                  args.push(redirection.destination);
                  if (Object.keys(redirection.path).length > 0) {
                    args.push([redirection.path[Object.keys(redirection.path)[0]]]);
                  }
                  if (Object.keys(redirection.queryParams).length > 0) {
                    args.push(_extends({}, redirection.queryParams));
                  }
                  if (redirection.destination !== "login") {
                    var _get2;

                    (_get2 = _this.get("routing")).transitionTo.apply(_get2, args);
                    return;
                  }
                }

                _this.get("routing").transitionTo(moveTo);
              })['catch'](function (error) {
                console.log(error);
                _this.logout();
              });
            }
          })['catch'](function (error) {
            console.log(error);
            _this.logout();
          })['finally'](function () {
            _this.set("fetchingSession", false);
          });
        }
      } catch (e) {
        _ember['default'].Logger.log("Failed to read user", e);
        this.logout();
      }
    },

    /**
    * Returns true when logged on.
    */
    authenticated: _ember['default'].computed("user", {
      get: function get() {
        return this.get("user") !== null;
      }
    }),

    /**
    * Provide with user data, arbitrary but expecting truish value.
    */
    login: function login(data, isAdminRefresh) {
      var _this2 = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {

        var apixclient = _this2.get("apixclient");
        var loginData = data;

        if (loginData.data[0].SubuserData) {
          loginData.data[0].ContactPerson = loginData.data[0].SubuserData.username;
          loginData.data[0].Email = loginData.data[0].SubuserData.username;
        }

        apixclient._xAuthServerGetToken(data.data[0]);
        apixclient.listContract(data.data[0].TransferID, data.data[0].TransferKey, data.data[0].UniqueCompanyID).then(function (data) {
          var showItems = {
            archives: true,
            traffic: true,
            invoices: true,
            tools: true,
            sell: true,
            buy: true,
            subusers: false,
            admin: false,
            filemanager: false,
            debtCollect: false,
            webshop: true,
            adminSelector: false
          };
          var laskumappiSkin = data.data.find(function (contract) {
            return contract.ContractType === 'LaskumappiSkin' && contract.ContractStatus === 'Active';
          }) || false;
          if (laskumappiSkin && laskumappiSkin.Parameters !== "svea") {
            reject(false);
            return;
          }

          var contract = data.data.find(function (contract) {
            return contract.ContractType === 'LaskumappiHideItems' && contract.ContractStatus === 'Active';
          }) || false;
          if (contract) {
            var hideItems = contract.Parameters.split(",");
            for (var item in showItems) {
              if (hideItems.includes(item)) {
                showItems[item] = false;
              }
            }
          }
          var filemanager = data.data.find(function (contract) {
            return contract.ContractType == 'StorageFileSystem' && contract.ContractStatus == 'Active';
          });
          var debtCollect = data.data.find(function (contract) {
            return contract.ContractType == 'LaskumappiDebtCollect' && contract.ContractStatus == 'Active';
          });
          showItems.filemanager = filemanager ? true : false;
          showItems.debtCollect = debtCollect ? true : false;
          var subuserContract = data.data.find(function (contract) {
            return contract.ContractType === 'Subuser' && contract.ContractStatus === 'Active';
          }) || false;
          if (subuserContract) {
            showItems.subusers = true;
          }
          if (loginData.data[0].SubuserData) {
            showItems.tools = false;
            showItems.sell = loginData.data[0].SubuserData.createSellInvoice;
            showItems.invoices = loginData.data[0].SubuserData.createPurchaseInvoice;
            showItems.buy = loginData.data[0].SubuserData.createPurchaseInvoice;
            showItems.traffic = showItems.archives = showItems.buy || showItems.sell;
            showItems.adminSelector = loginData.data[0].SubuserData.adminSelector || false;
          }

          var tid = "";
          var tkey = "";
          var uid = "";
          if (isAdminRefresh) {
            tid = isAdminRefresh.mainUser.tid;
            tkey = isAdminRefresh.mainUser.tkey;
            uid = isAdminRefresh.mainUser.uid;
          } else {
            tid = loginData.data[0].TransferID;
            tkey = loginData.data[0].TransferKey;
            uid = loginData.data[0].UniqueCompanyID;
          }

          apixclient.listUsers(tid, tkey, uid).then(function (data) {
            if (data.callStatus.success === "OK") {
              var users = data.data;
              showItems.adminSelector = true;
              var subuserData = null;
              if (loginData.data[0].SubuserData) {
                showItems.adminSelector = loginData.data[0].SubuserData.adminSelector;

                showItems.subusers = false;
                showItems.admin = false;
                subuserData = loginData.data[0].SubuserData;
              } else if (isAdminRefresh && isAdminRefresh.mainUser.subuserData) {
                showItems.admin = false;
                subuserData = isAdminRefresh.mainUser.subuserData;
              } else {
                showItems.admin = true;
              }

              var adminSession = {
                mainUser: {
                  tid: tid,
                  tkey: tkey,
                  uid: uid,
                  subuserData: subuserData
                }
              };
              window.localStorageAlias.setItem("adminSession", JSON.stringify(adminSession));
              adminSession.users = users;

              _this2.set("adminSession", adminSession);
            }

            loginData.data[0].ShowItems = showItems;
            _this2.set("showItems", showItems);
            _this2.set("user", loginData.data);
            _this2.get("user").notifyPropertyChange();
            var session = {
              TransferID: loginData.data[0].TransferID,
              TransferKey: loginData.data[0].TransferKey
            };
            _this2.get("saldoupdater").update_saldo(_this2, loginData.data[0]);
            _this2.get("saldoupdater").add_saldo_poller(_this2);
            _this2.idleLogout();

            window.localStorage.setItem("session", JSON.stringify(session));
            if (laskumappiSkin && laskumappiSkin.Parameters === "financelink") {
              resolve("invoice.create.sell");
            } else {
              resolve("traffic");
            }
          })['catch'](function (error) {
            console.log(error);
          });
        });
      });
    },

    /**
    * Log out current user.
    */
    logout: function logout() {
      var user = this.get("user");
      var adminSession = this.get("adminSession");

      if (user && user[0]) {
        user = user[0];
        if (user.TransferID && user.TransferKey) {
          this.get("apixclient").deleteSession(user.TransferID, user.TransferKey);
        }
      }

      if (adminSession) {
        if (user && user.TransferID && adminSession.mainUser.tid !== user.TransferID) {
          this.get("apixclient").deleteSession(adminSession.mainUser.tid, adminSession.mainUser.tkey);
        } else if (!user) {
          this.get("apixclient").deleteSession(adminSession.mainUser.tid, adminSession.mainUser.tkey);
        }
      }

      this.set("user", null);
      if (window.Tawk_API) {
        window.Tawk_API.hideWidget();
        this.set("showChat", false);
      }
      this.set("showItems", null);
      this.set("adminSession", null);
      window.localStorageAlias.removeItem("session");
      window.localStorageAlias.removeItem("adminSession");
      window.localStorageAlias.removeItem("userData");
      this.get("routing").transitionTo("login");
    },

    adminChangeUser: function adminChangeUser(user) {
      var mainUser = this.get("adminSession.mainUser");
      var apixclient = this.get("apixclient");
      var loggedInUser = this.get("user")[0];

      if (user.UniqueID == mainUser.uid) {
        this.get("apixclient").deleteSession(loggedInUser.TransferID, loggedInUser.TransferKey);

        var session = {
          TransferID: mainUser.tid,
          TransferKey: mainUser.tkey
        };
        window.localStorageAlias.setItem("session", JSON.stringify(session));
        setTimeout(function () {
          window.location.reload(false);
        }, 1000);
      } else {
        if (loggedInUser.TransferID !== mainUser.tid) {
          this.get("apixclient").deleteSession(loggedInUser.TransferID, loggedInUser.TransferKey);
        }
        apixclient.getUser(mainUser.tid, mainUser.tkey, user.UniqueID).then(function (data) {
          var session = {
            TransferID: data.data[0].TransferID,
            TransferKey: data.data[0].TransferKey
          };
          window.localStorageAlias.setItem("session", JSON.stringify(session));
          setTimeout(function () {
            window.location.reload(false);
          }, 1000);
        });
      }
    },
    idleLogout: function idleLogout() {
      var t;
      window.onload = resetTimer;
      window.onmousemove = resetTimer;
      window.onmousedown = resetTimer; // catches touchscreen presses as well
      window.ontouchstart = resetTimer; // catches touchscreen swipes as well
      window.onclick = resetTimer; // catches touchpad clicks as well
      window.onkeydown = resetTimer;
      window.addEventListener('scroll', resetTimer, true);
      var that = this;
      function yourFunction() {
        if (that.get("user")) {
          that.logout();
        }
      }

      function resetTimer() {
        if (that.get("user")) {
          clearTimeout(t);
          t = setTimeout(yourFunction, 7200000); // 2hrs
        } else {
            if (t) {
              clearTimeout(t);
              t = null;
            }
          }
      }
    }
  });
});