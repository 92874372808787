define('incus-vastaanota/pods/traffic/received/route', ['exports', 'ember', 'incus-vastaanota/config/environment', 'incus-vastaanota/models/traffic', 'incus-vastaanota/utils/date-utils', 'incus-vastaanota/utils/list-utils', 'ember-cli-pagination/computed/paged-array', 'incus-vastaanota/models/receivedtraffic'], function (exports, _ember, _incusVastaanotaConfigEnvironment, _incusVastaanotaModelsTraffic, _incusVastaanotaUtilsDateUtils, _incusVastaanotaUtilsListUtils, _emberCliPaginationComputedPagedArray, _incusVastaanotaModelsReceivedtraffic) {
  exports['default'] = _ember['default'].Route.extend({

    apixclient: _ember['default'].inject.service(),

    model: function model(param) {
      var apixclient = this.get("apixclient");
      var userData = this.get("session").get("user")[0];

      var locale = _incusVastaanotaConfigEnvironment['default'].defaultLocale;
      if (window.localStorage) {
        locale = window.localStorage.getItem('locale');
      }

      return apixclient.ReceivedTraffic(userData.UniqueCompanyID, userData.TransferID, userData.TransferKey, 1000).then(function (data) {
        var invoiceList = _incusVastaanotaUtilsListUtils['default'].processTrafficData(data, apixclient, userData, function () {
          return _incusVastaanotaModelsReceivedtraffic['default'].create();
        }, "received");
        _incusVastaanotaUtilsListUtils['default'].processTrafficLightQuery(invoiceList, apixclient, userData, "received");

        var model = _ember['default'].Object.extend({
          fields: [{
            fieldName: 'picture',
            numeric: false,
            sortable: false
          }, {
            fieldName: 'data',
            numeric: false,
            sortable: false
          }, {
            fieldName: 'invoiceNumber',
            numeric: false,
            sortable: true
          }, {
            fieldName: 'exitTime',
            fieldTranslationName: 'receivedDate',
            numeric: false,
            sortable: true
          }, {
            fieldName: 'recipient',
            numeric: false,
            sortable: true
          }, {
            fieldName: 'sender',
            numeric: false,
            sortable: true
          }, {
            fieldName: 'messageType',
            numeric: false,
            sortable: true
          }, {
            fieldName: 'deliveryMethod',
            numeric: false,
            sortable: true
          }, {
            fieldName: 'attachments',
            numeric: false,
            sortable: true
          }],

          page: 1,
          perPage: 100,

          pagedContent: (0, _emberCliPaginationComputedPagedArray['default'])('invoiceListDynamic', { pageBinding: "page", perPageBinding: "perPage" }),
          totalPagesBinding: "pagedContent.totalPages",

          sortProperty: 'exitTime',
          sortDirection: true,
          sortNumeric: false,

          queryDone: true,

          // Used for filtering the resultset by date
          startDate: moment().subtract(30, 'day').toDate(),
          endDate: new Date(),
          dueDate: '',
          receiverFilter: '',
          documentIDFilter: '',
          freeText: '',
          locale: locale,

          invoiceListDynamic: _ember['default'].computed('sortProperty', 'sortDirection', 'sortNumeric', 'receiverFilter', 'invoiceList.[]', function () {
            var list = this.get("invoiceList");
            var receiverFilter = this.get('receiverFilter');
            var invoiceArray = list.filter(function (invoice) {
              var includeInvoice = true;
              if (receiverFilter) {
                _ember['default'].Logger.log(receiverFilter);
                var re = new RegExp(receiverFilter, 'i');
                if (!re.test(invoice.receiverName)) {
                  includeInvoice = false;
                }
              }
              return includeInvoice;
            });

            var sortProperty = this.get("sortProperty");
            var sortNumeric = this.get("sortNumeric");
            var sortDirection = this.get("sortDirection");
            if (sortProperty) {
              invoiceArray.sort(_incusVastaanotaUtilsListUtils['default'].dynamicSort(sortProperty, sortDirection, sortNumeric));
            }

            console.log("Sorted by", sortProperty);

            this.set('queryDone', true);
            this.set('resultCount', invoiceArray.length);
            return invoiceArray;
          })
        }).create({
          invoiceList: invoiceList
        });

        //console.log(model.pagedContent);

        return model;
      });
    },

    actions: {
      setSortProperty: function setSortProperty(sortProperty, numeric, sortable) {
        if (!sortable) {
          return;
        }
        var currentSort = this.get("controller.model.sortProperty");
        if (currentSort === sortProperty) {
          var sortDirection = this.get("controller.model.sortDirection");
          this.set("controller.model.sortDirection", !sortDirection);
        } else {
          this.set("controller.model.sortDirection", false);
        }
        if (numeric) {
          this.set("controller.model.sortNumeric", true);
        } else {
          this.set("controller.model.sortNumeric", false);
        }
        this.set("controller.model.sortProperty", sortProperty);
      },

      clearAllDateFilters: function clearAllDateFilters() {
        this.set('controller.model.startDate', moment().subtract(30, 'day').toDate());
        this.set('controller.model.endDate', new Date());
        this.set('controller.model.dueDate', '');
        this.set('controller.model.receiverFilter', '');
        this.set('controller.model.documentIDFilter', '');
        this.set('controller.model.freeText', '');
      },

      getMetadata: function getMetadata(item) {
        var apixclient = this.get("apixclient");
        var userData = this.get("session").get("user")[0];
        var showDetails = item.get("showDetails");
        var meta = item.get("meta");

        if (showDetails === true) {
          item.set("showDetails", false);
          return;
        } else if (meta) {
          item.set("showDetails", true);
          return;
        }

        console.log([userData.TransferID, item.receiverArchiveId, userData.TransferKey]);
        apixclient.getMetadata(userData.TransferID, item.receiverArchiveId, userData.TransferKey).then(function (response) {
          var data = response.data[0];

          var dates = ["DueDate", "DocumentDate", "CreatedDate"];

          if (data) {
            for (var i = dates.length - 1; i >= 0; i--) {
              var key = dates[i];

              if (data[key]) {
                var value = _incusVastaanotaUtilsDateUtils['default'].parseTime(data[key], false);
                data[key] = _incusVastaanotaUtilsDateUtils['default'].formatTime(value, false);
              }
            }
          }

          item.set("meta", data);
          item.set("showDetails", true);
        });
      }

    }

  });
});