define("incus-vastaanota/pods/archives/index/controller", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Controller.extend({
        apixclient: _ember["default"].inject.service(),
        countryselector: _ember["default"].inject.service(),
        session: _ember["default"].inject.service(),

        actions: {
            initIframe: function initIframe() {
                var model = this.get("model");
                var apixclient = this.get("apixclient");
                var userData = this.get("session").get("user")[0];
                var url = this.get("countryselector.url");
                var that = this;
                iFrameResize({
                    log: false,
                    heightCalculationMethod: 'lowestElement',
                    initCallback: function initCallback(iframe) {
                        var userinfo = Object.assign({}, that.get("session.user")[0]);

                        var locale = window.localStorage.getItem("locale");
                        if (url === "laskumappi" && locale === 'sv') {
                            locale = 'svfi';
                        }
                        userinfo.locale = locale;
                        userinfo.entryPoint = url;
                        iframe.iFrameResizer.sendMessage(userinfo);
                    },
                    messageCallback: function messageCallback(msg) {
                        var command = JSON.parse(msg.message);
                        if (command.type === "setContract") {
                            apixclient.listContract(userData.TransferID, userData.TransferKey, userData.UniqueCompanyID).then(function (data) {
                                if (!data.data.find(function (contract) {
                                    return contract.ContractType === "LaskumappiDebtCollect";
                                })) {
                                    apixclient.setContract(userData.TransferID, userData.TransferKey, userData.UniqueCompanyID, that._requestDataString("LaskumappiDebtCollect", command.value)).then(function (response) {
                                        console.log("debt collecting contract set");
                                    });
                                }
                            });
                        }
                    } }, '#archive');
            }
        },
        _requestDataString: function _requestDataString(contractType, parameters) {
            var doc = window.document.implementation.createDocument(null, "Request", null);
            var request = doc.firstChild;
            request.setAttribute("version", "1.0");
            var content = doc.createElement("Content");
            var group = doc.createElement("Group");

            group.appendChild(this._valueElement(doc, "ContractType", contractType));
            group.appendChild(this._valueElement(doc, "ContractStatus", "ACTIVE"));
            group.appendChild(this._valueElement(doc, "Parameters", parameters));

            content.appendChild(group);
            request.appendChild(content);
            var xs = new XMLSerializer();
            var header = "<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"yes\" ?>\n";
            //header = "";
            return header + xs.serializeToString(doc);
        },
        _valueElement: function _valueElement(doc, type, text) {
            var el = doc.createElement("Value");
            el.setAttribute("type", type);
            el.textContent = text;
            return el;
        }
    });
});