define('incus-vastaanota/services/saldoupdater', ['exports', 'ember', 'incus-vastaanota/utils/poller', 'incus-vastaanota/config/environment'], function (exports, _ember, _incusVastaanotaUtilsPoller, _incusVastaanotaConfigEnvironment) {

  /* CountrySelector keeps track of selected country */

  exports['default'] = _ember['default'].Service.extend({
    apixclient: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    routing: _ember['default'].inject.service('-routing'),

    caller: null,

    init: function init() {
      this._super.apply(this, arguments);
    },

    /* Saldo updating function */
    update_saldo: function update_saldo(caller) {
      var _this = this;

      if (caller) {
        this.caller = caller;
      }

      var apixclient = this.get("apixclient");
      var user_data = this.caller.get("user.0");

      if (user_data) {
        var promise = apixclient.GetSaldo(user_data.UniqueCompanyID);

        _ember['default'].RSVP.resolve(promise).then(function (data) {
          var saldo = Math.floor(data.data[0].Saldo * 100) / 100;

          // saldo == 90000 -> invoiced
          if (saldo < 90000) {
            _this.get("session").set("saldo", saldo);
            _this.caller.set("user.0.Saldo", saldo);
            _this.get("session").set("isInvoicingCustomer", false);
          } else {
            _this.get("session").set("saldo", null);
            _this.get("session").set("isInvoicingCustomer", true);
          }
          if (user_data.SubuserData) {
            (function () {
              var updatedAt = user_data.SubuserData.updatedAt;
              apixclient.checkSubuser(user_data, user_data.TransferID, false).then(function (res) {
                if (res.ts) {
                  var date = moment(res.ts, 'YYYY-MM-DD hh:mm:ss');
                  if (moment(updatedAt, 'YYYY-MM-DD hh:mm:ss').isBefore(date)) {
                    _this.get("session").logout();
                    _this.get("routing").transitionTo('login');
                  }
                } else {
                  _this.get("session").logout();
                  _this.get("routing").transitionTo('login');
                }
              })['catch'](function (error) {
                console.log(error);
                _this.get("session").logout();
                _this.get("routing").transitionTo('login');
              });
            })();
          } else if (_this.get("session.adminSession")) {
            var adminSession = _this.get("session.adminSession");
            if (adminSession.mainUser.subuserData) {
              (function () {
                var updatedAt = adminSession.mainUser.subuserData.updatedAt;
                var userData = {
                  TransferID: adminSession.mainUser.tid,
                  TransferKey: adminSession.mainUser.tkey
                };
                apixclient.checkSubuser(userData, adminSession.mainUser.tid, false).then(function (res) {
                  if (res.ts) {
                    var date = moment(res.ts, 'YYYY-MM-DD hh:mm:ss');
                    if (moment(updatedAt, 'YYYY-MM-DD hh:mm:ss').isBefore(date)) {
                      _this.get("session").logout();
                      _this.get("routing").transitionTo('login');
                    }
                  } else {
                    _this.get("session").logout();
                    _this.get("routing").transitionTo('login');
                  }
                })['catch'](function (error) {
                  console.log(error);
                  _this.get("session").logout();
                  _this.get("routing").transitionTo('login');
                });
              })();
            }
          }
        });
      }
    },

    delayed_update_saldo: function delayed_update_saldo(caller, delay) {
      if (caller) {
        this.caller = caller;
      }

      if (!delay) {
        delay = 10000;
      }

      _ember['default'].run.later(this, this.update_saldo, caller, delay);
    },

    add_saldo_poller: function add_saldo_poller(caller) {
      this.caller = caller;

      if (_incusVastaanotaConfigEnvironment['default'].SaldoUpdateInterval > 0) {

        /* Intervalled update */
        var p = new _incusVastaanotaUtilsPoller['default']();
        p.setInterval(_incusVastaanotaConfigEnvironment['default'].SaldoUpdateInterval);
        p.start(this, this.update_saldo);
      }
    }

  });
});