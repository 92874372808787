define('incus-vastaanota/router', ['exports', 'ember', 'incus-vastaanota/config/environment'], function (exports, _ember, _incusVastaanotaConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _incusVastaanotaConfigEnvironment['default'].locationType,
    session: _ember['default'].inject.service(),
    countryselector: _ember['default'].inject.service(),

    didTransition: function didTransition() {
      this._super.apply(this, arguments);
    }

  });

  Router.map(function () {

    //this.route('x');

    this.route('error', {
      path: '/error/:p1:p2:p3:p4'
    });
    this.route('invoice', function () {
      this.route('view', { path: '/invoice/view/:storageId' });
      this.route('create', function () {
        this.route('sell', function () {
          if (_incusVastaanotaConfigEnvironment['default'].build === "laskumappi") {
            this.route('upload');
          }
        });
      });
    });

    this.route('storage', function () {
      this.route('view', { path: '/view/:storageId' });
    });

    this.route('traffic', function () {
      this.route('pitstop', function () {
        this.route('advertisement', { path: 'advertisement/:selected' });
        this.route('confirm', { path: 'confirm/:selected' });
        this.route('end', { path: 'end/:selected' });
        this.route('list');
        this.route('view');
      });

      this.route('sent');
    });

    this.route('archives', function () {
      this.route('received');
      this.route('sent');
    });

    //  this.route('error');
    this.route('list');
    this.route('login');
    this.route('logout');

    this.route('tools', function () {

      // this.route('user');
      this.route('settings');
      this.route('admin');
    });

    this.route('terms');
  });

  exports['default'] = Router;
});