define("incus-vastaanota/pods/tools/settings/route", ["exports", "ember", "incus-vastaanota/utils/email", "incus-vastaanota/config/environment", "incus-vastaanota/utils/countrycodes", "incus-vastaanota/utils/validate-utils"], function (exports, _ember, _incusVastaanotaUtilsEmail, _incusVastaanotaConfigEnvironment, _incusVastaanotaUtilsCountrycodes, _incusVastaanotaUtilsValidateUtils) {
  exports["default"] = _ember["default"].Route.extend({
    apixclient: _ember["default"].inject.service(),
    countryselector: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),

    model: function model() {
      var _this = this;

      var model = _ember["default"].Object.extend({
        wrongFormat: false,
        eInvoiceAddress: "",
        imageURL: "",
        queryDone: false,
        passwordStrengthClass: "neutral",
        name: "",
        businessID: "",
        address: "",
        city: "",
        zip: "",
        country: "",
        bankAccounts: [],
        nameObs: _ember["default"].observer("name", function () {
          if (this.get("name")) {
            this.set("validations.name", true);
          } else {
            this.set("validations.name", false);
          }
        }),
        addressObs: _ember["default"].observer("address", function () {
          if (this.get("address")) {
            this.set("validations.address", true);
          } else {
            this.set("validations.address", false);
          }
        }),
        cityObs: _ember["default"].observer("city", function () {
          if (this.get("city")) {
            this.set("validations.city", true);
          } else {
            this.set("validations.city", false);
          }
        }),
        zipObs: _ember["default"].observer("zip", function () {
          if (this.get("zip")) {
            this.set("validations.zip", true);
          } else {
            this.set("validations.zip", false);
          }
        }),
        validations: {
          name: false,
          address: false,
          city: false,
          zip: false
        },
        rowId: 0,
        codes: (0, _incusVastaanotaUtilsCountrycodes["default"])(this.get("i18n.locale")),
        build: _incusVastaanotaConfigEnvironment["default"].build,
        selectedCountry: "FI",
        contactPerson: "",
        phone: "",
        www: "",
        email: "",
        fax: "",
        penaltyInterest: "",
        selectedPaymentCondition: 1,
        paymentConditionDays: [14, 21, 30, 45, 60, 90],
        accountTypes: ["IBAN", "BANKGIRO", "PLUSGIRO"],
        i18n: this.get("i18n"),
        countryselector: this.get("countryselector"),
        /* copy locale to localstraoge on change */
        localeObserver: _ember["default"].observer("i18n.locale", function () {
          var locale = this.get("i18n.locale");
          this.set("codes", (0, _incusVastaanotaUtilsCountrycodes["default"])(locale));
          _ember["default"].Logger.log("Setting locale to", locale);
          if (!window.localStorageAlias) {
            window.localStorageAlias = window.localStorage;
            if (!window.localStorage) {
              window.localStorageAlias = {};
              window.localStorageAlias.removeItem = function () {};
              window.localStorageAlias.getItem = function (item) {
                return this[item];
              };
              window.localStorageAlias.setItem = function (item, value) {
                this[item] = value;
              };
            }
          }
          window.localStorageAlias.setItem("locale", locale);
        }),
        bankAccountsObserver: _ember["default"].observer("bankAccounts.@each.Value", "bankAccounts.@each.Type", function () {
          var accs = this.get("bankAccounts");
          for (var i = 0; accs.length > i; i++) {
            var row = this.get("bankAccounts").objectAt(i);
            switch (row.Type) {
              case "IBAN":
                _ember["default"].set(this.get("bankAccounts").objectAt(i), "valid", IBAN.isValid(row.Value));
                break;
              case "BANKGIRO":
                _ember["default"].set(this.get("bankAccounts").objectAt(i), "valid", validateBankgiro(row.Value));
                break;
              case "PLUSGIRO":
                _ember["default"].set(this.get("bankAccounts").objectAt(i), "valid", validatePlusgiro(row.Value));
                break;
            }
          }
        }),
        logoLoaded: _ember["default"].computed("imageURL", "queryDone", function () {
          return _this.get("controller.model.imageURL") && _this.get("controller.model.queryDone");
        }),
        //USER SETTINGS

        newPass: '',
        oldPass: '',
        repeatPass: '',
        queryDone: false,
        settingsName: '',
        settingsAdditionalName: '',
        address: '',
        city: '',
        zip: '',
        country: '',
        codes: (0, _incusVastaanotaUtilsCountrycodes["default"])(this.get("i18n.locale")),
        build: _incusVastaanotaConfigEnvironment["default"].build,
        selectedCountry: "FI",
        settingsContactPerson: '',
        settingsPhone: '',
        settingsWww: '',
        i18n: this.get("i18n"),
        selectedAddress: "Official",
        lowellDebtCollectionActive: null,
        addressTypes: [{ value: "Official", tstring: "settings.userInfo.officialAddress" }, { value: "Billing", tstring: "settings.userInfo.billingAddress" }, { value: "Visiting", tstring: "settings.userInfo.visitingAddress" }],
        addresses: [{
          AddressType: "Official",
          Country: "",
          PostalCode: "",
          PostalOffice: "",
          Street1: "",
          Street2: ""
        }, {
          AddressType: "Visiting",
          Country: "",
          PostalCode: "",
          PostalOffice: "",
          Street1: "",
          Street2: ""
        }, {
          AddressType: "Billing",
          Country: "",
          PostalCode: "",
          PostalOffice: "",
          Street1: "",
          Street2: ""
        }],
        validations: {
          newPasswords: null,
          Official: {
            Country: '',
            PostalCode: '',
            PostalOffice: '',
            Street1: '',
            Street2: ''
          },
          Visiting: {
            Country: '',
            PostalCode: '',
            PostalOffice: '',
            Street1: '',
            Street2: ''
          },
          Billing: {
            Country: '',
            PostalCode: '',
            PostalOffice: '',
            Street1: '',
            Street2: ''
          }
        },
        addressesObserver: _ember["default"].observer("addresses.@each.Country", "addresses.@each.PostalCode", "addresses.@each.PostalOffice", "addresses.@each.Street1", "addresses.@each.Street2", function () {
          var _this2 = this;

          var addresses = this.get("addresses");
          /*jshint loopfunc: true */

          var _loop = function (i) {
            var empty = true;
            Object.keys(addresses[i]).forEach(function (key) {
              if (addresses[i][key] !== '' && key !== "AddressType") {
                empty = false;
              }
            });
            if (!empty) {
              Object.keys(addresses[i]).forEach(function (key) {
                if (addresses[i][key] !== '' && key !== "AddressType") {
                  _ember["default"].set(_this2.get("validations." + addresses[i].AddressType), key, 'is-valid');
                } else {
                  _ember["default"].set(_this2.get("validations." + addresses[i].AddressType), key, 'is-invalid');
                }
              });
            } else {
              Object.keys(addresses[i]).forEach(function (key) {
                _ember["default"].set(_this2.get("validations." + addresses[i].AddressType), key, '');
              });
            }
          };

          for (var i = 0; addresses.length > i; i++) {
            _loop(i);
          }
        }),
        newPasswordsObserver: _ember["default"].observer("newPass", "repeatPass", function () {
          var newPass = this.get("newPass");

          var _zxcvbn = zxcvbn(newPass);

          var score = _zxcvbn.score;

          switch (score) {
            case 1:
              this.set("passwordStrengthClass", "red");
              break;
            case 2:
              this.set("passwordStrengthClass", "orange");
              break;
            case 3:
              this.set("passwordStrengthClass", "yellow");
              break;
            case 4:
              this.set("passwordStrengthClass", "green");
              break;
            default:
              this.set("passwordStrengthClass", "neutral");
              break;
          }
          var repeatPass = this.get("repeatPass");
          if (newPass && repeatPass) {
            if (newPass === repeatPass && _incusVastaanotaUtilsValidateUtils["default"].validatePassword(newPass)) {
              this.set("validations.newPasswords", "is-valid");
            } else {
              this.set("validations.newPasswords", "is-invalid");
            }
          }
        })

      }).create();

      function validateBankgiro(value) {
        var parts = value.split("-");
        if (value.includes('-') && value.length >= 7) {
          if (parts[0].length === 3 | parts[0].length === 4 && parts[1].length === 4) {
            var serial = parts[0].concat(parts[1]);
            var sum = 0;
            var start = (serial.length - 1) % 2 === 0 ? 1 : 0;
            for (var i = 0; i < serial.length - 1; i++) {
              var digit = undefined;
              if (start === 1) {
                if (i % 2 !== 0 && i !== 0) {
                  digit = Number(serial.charAt(i)) * 2;
                  if (digit >= 10) {
                    var firstNum = Number(digit.toString().charAt(0));
                    var secNum = Number(digit.toString().charAt(1));
                    sum += firstNum + secNum;
                  } else {
                    sum += digit;
                  }
                } else {
                  digit = Number(serial.charAt(i));
                  sum += digit;
                }
              } else {
                if (i % 2 === 0 || i === 0) {
                  digit = Number(serial.charAt(i)) * 2;
                  if (digit >= 10) {
                    var firstNum = Number(digit.toString().charAt(0));
                    var secNum = Number(digit.toString().charAt(1));
                    sum += firstNum + secNum;
                  } else {
                    sum += digit;
                  }
                } else {
                  digit = Number(serial.charAt(i));
                  sum += digit;
                }
              }
            }
            if ((sum + Number(serial.charAt(serial.length - 1))) % 10 === 0) {
              return true;
            }
            return false;
          }
        }
        return false;
      }
      function validatePlusgiro(value) {
        var parts = value.split("-");
        if (parts[0].length === 6 || parts[0].length === 5 && parts[1].length === 1) {
          var serial = parts[0];
          var sum = 0;
          for (var i = 0; i < serial.length; i++) {
            var digit = undefined;
            if (parts[0].length === 6) {
              if (i % 2 !== 0 && i !== 0) {
                digit = Number(serial.charAt(i)) * 2;
                if (digit >= 10) {
                  var firstNum = Number(digit.toString().charAt(0));
                  var secNum = Number(digit.toString().charAt(1));
                  sum += firstNum + secNum;
                } else {
                  sum += digit;
                }
              } else {
                digit = Number(serial.charAt(i));
                sum += digit;
              }
            } else {
              if (i % 2 === 0 || i === 0) {
                digit = Number(serial.charAt(i)) * 2;
                if (digit >= 10) {
                  var firstNum = Number(digit.toString().charAt(0));
                  var secNum = Number(digit.toString().charAt(1));
                  sum += firstNum + secNum;
                } else {
                  sum += digit;
                }
              } else {
                digit = Number(serial.charAt(i));
                sum += digit;
              }
            }
          }
          if ((sum + Number(parts[1])) % 10 === 0) {
            return true;
          }
          return false;
        }
        return false;
      }
      return model;
    },
    afterModel: function afterModel(model) {
      var _this3 = this;

      var apixclient = this.get("apixclient");
      var userData = this.get("session").get("user")[0];
      var addresses = this.get("session").get("user")[1];
      if (Array.isArray(addresses)) {
        addresses = addressses[0];
      }
      model.set("settingsName", userData.Name);
      model.set("settingsAdditionalName", userData.AdditionalName);
      model.set("settingsContactPerson", userData.ContactPerson);
      model.set("settingsWww", userData.Www);
      model.set("settingsPhone", userData.Phonenumber);

      apixclient.fetchInvoicingCustomerData(userData).then(function (data) {
        var Name = undefined,
            BusinessID = undefined,
            Street1 = "",
            City = "",
            PostCode = "",
            eInvoiceAddress = { Type: "EINVOICEADDRESS", Value: "" },
            ContactPerson = undefined,
            Www = undefined,
            PhoneNumber = undefined,
            Email = undefined,
            Fax = { Type: "FAX", Value: "" },
            CountryCode = "",
            Data = undefined,
            BankAccounts = undefined,
            Vatid = "",
            PenaltyInterest = undefined,
            DefaultPaymentDays = 1,
            rowId = 0;
        if (!data.AnyParty) {
          Name = userData.Name || "";
          BusinessID = userData.Ytunnus || "";
          ContactPerson = userData.ContactPerson || "";
          Www = userData.Www || "";
          PhoneNumber = userData.Phonenumber || "";
          Email = userData.Email || "";
          PenaltyInterest = "";
          if (addresses) {
            CountryCode = addresses.Country;
            Street1 = addresses.Street1;
            City = addresses.PostalOffice;
            PostCode = addresses.PostalCode;
          }
          if (CountryCode === "FI") {
            Vatid = "FI" + BusinessID.replace("-", "");
            eInvoiceAddress = {
              Type: "EINVOICEADDRESS",
              Value: "0037" + BusinessID.replace("-", "")
            };
          }
          BankAccounts = [];
          var Role = "ISSUER";
          var CustomerNumber = -1;
          Data = [{ Type: "EMAIL", Value: Email }, { Type: "PHONE", Value: PhoneNumber }, { Type: "WWW", Value: Www }, Fax, eInvoiceAddress];
          var _data = {
            ContactPerson: ContactPerson,
            AnyParty: {
              Name: Name,
              BusinessID: BusinessID,
              Street1: Street1,
              City: City,
              PostCode: PostCode,
              Role: Role,
              Vatid: Vatid,
              CustomerNumber: CustomerNumber,
              CountryCode: CountryCode
            },
            Data: Data
          };
          apixclient.updateInvoicingCustomerData(userData, _data).then(function (response) {
            if (response.success) {
              console.log("Updated customerdata");
            }
          });
        } else {
          DefaultPaymentDays = data.DefaultPaymentDays || 1;
          PenaltyInterest = data.AnyParty.PenaltyInterest || 0;
          Name = data.AnyParty.Name || "";
          BusinessID = data.AnyParty.BusinessID || "";
          Street1 = data.AnyParty.Street1 || "";
          City = data.AnyParty.City || "";
          CountryCode = data.AnyParty.CountryCode || "FI";
          PostCode = data.AnyParty.PostCode || "";
          ContactPerson = data.ContactPerson || "";
          Www = data.Data.find(function (row) {
            return row.Type === "WWW";
          }) || "";
          PhoneNumber = data.Data.find(function (row) {
            return row.Type === "PHONE";
          }) || "";
          Email = data.Data.find(function (row) {
            return row.Type === "EMAIL";
          }) || "";
          BankAccounts = data.Data.filter(function (row) {
            return row.Type === "BANKGIRO" || row.Type === "POSTGIRO" || row.Type === "IBAN";
          }) || [];
          BankAccounts = BankAccounts.map(function (account) {
            account.id = rowId++;
            return account;
          });
          eInvoiceAddress = data.Data.find(function (row) {
            return row.Type === "EINVOICEADDRESS";
          }) || "";
          Fax = data.Data.find(function (row) {
            return row.Type === "FAX";
          }) || "";
        }
        model.set("penaltyInterest", PenaltyInterest);
        model.set("name", Name);
        model.set("selectedCountry", CountryCode);
        model.set("businessID", BusinessID);
        model.set("address", Street1);
        model.set("city", City);
        model.set("zip", PostCode);
        model.set("contactPerson", ContactPerson);
        model.set("www", Www.Value || "");
        model.set("email", Email.Value || "");
        model.set("phone", PhoneNumber.Value || "");
        model.set("fax", Fax.Value || "");
        model.set("selectedPaymentCondition", DefaultPaymentDays);
        if (BankAccounts.length === 0) {
          BankAccounts = [{ Type: "IBAN", Value: "", id: 0 }];
          model.set("rowId", 1);
        }
        model.set("bankAccounts", BankAccounts);
        if (CountryCode === "FI" && (eInvoiceAddress === "" || eInvoiceAddress.Value === "")) {
          model.set("eInvoiceAddress", "0037" + BusinessID.replace("-", ""));
          _this3.get("controller").send("updateCustomer");
        } else if (eInvoiceAddress === "" || eInvoiceAddress.Value === "") {
          model.set("eInvoiceAddress", BusinessID.replace("-", ""));
          _this3.get("controller").send("updateCustomer");
        } else {
          model.set("eInvoiceAddress", eInvoiceAddress.Value || "");
        }
        model.set("queryDone", true);
      });

      apixclient.fetchInvoicingCustomerLogo(userData).then(function (data) {
        model.set("imageURL", data.Logo);
      });
      var addressData = this.get("session").get("user");
      addressData = addressData.slice(1, addressData.length);
      addressData.forEach(function (element) {
        var addressIndex = model.get("addresses").findIndex(function (addr) {
          return addr.AddressType === element.AddressType;
        });
        var addressToUpdate = model.get("addresses").objectAt(addressIndex);
        Object.keys(element).forEach(function (key) {
          _ember["default"].set(addressToUpdate, key, element[key]);
        });
      });
    },
    actions: {

      fileChanged: function fileChanged(file) {
        var model = this.get("controller.model");
        var reader = new FileReader();
        reader.onload = function () {
          model.set("imageURL", reader.result);
        };
        console.log(file);
        if ((file.type === "image/jpeg" || file.type === "image/png") && file.size <= 300000) {
          model.set("wrongFormat", false);
          reader.readAsDataURL(file);
        } else {
          model.set("wrongFormat", true);
          model.set("imageURL", "");
        }
      }

    }

  });
});