define("incus-vastaanota/pods/tools/admin/controller", ["exports", "ember"], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports["default"] = _ember["default"].Controller.extend({
    apixclient: _ember["default"].inject.service(),
    countryselector: _ember["default"].inject.service(),
    session: _ember["default"].inject.service(),

    actions: {
      initIframe: function initIframe() {
        var apixclient = this.get("apixclient");
        var url = this.get("countryselector.url");
        var that = this;
        iFrameResize({
          log: false,
          heightCalculationMethod: 'bodyScroll',
          initCallback: function initCallback(iframe) {
            var userinfo = Object.assign({}, that.get("session.user")[0]);

            userinfo.asUser = userinfo.UniqueCompanyID;
            userinfo.TransferID = that.get("session.adminSession.mainUser.tid");
            userinfo.TransferKey = that.get("session.adminSession.mainUser.tkey");
            userinfo.UniqueCompanyID = that.get("session.adminSession.mainUser.uid");
            var locale = window.localStorage.getItem("locale");
            if (url === "laskumappi" && locale === 'sv') {
              locale = 'svfi';
            }
            userinfo.locale = locale;
            userinfo.entryPoint = url;
            userinfo.allUsers = that.get("session.adminSession.users");
            iframe.iFrameResizer.sendMessage(userinfo);
          },
          messageCallback: function messageCallback(msg) {
            var command = JSON.parse(msg.message);
            if (command.type === "reloadUsers") {
              var mainUser = that.get("session.adminSession.mainUser");
              if (mainUser) {
                apixclient.listUsers(mainUser.tid, mainUser.tkey, mainUser.uid).then(function (data) {
                  that.set("session.adminSession.users", data.data);
                })["catch"](function (error) {
                  console.log(error);
                });
              }
            } else if (command.type === "updateCurrentUser") {
              var user = that.get("session.user");
              // let currUser = that.get("session.adminSession.currentUser")
              if (user[0].UniqueCompanyID === command.value.UniqueID) {
                _ember["default"].set(user.objectAt(0), "Name", command.value.Name);
                _ember["default"].set(user.objectAt(0), "Www", command.value.WWW);
                _ember["default"].set(user.objectAt(0), "Phonenumber", command.value.PhoneNumber);
                _ember["default"].set(user.objectAt(0), "ContactPerson", command.value.ContactPerson);
                user = [user[0]].concat(_toConsumableArray(command.value.userAddresses));
                _ember["default"].set(that.get("session"), "user", user);
              }
            } else if (command.type === "deleteUser") {
              that.get("session.adminSession.users").removeAt(command.value);
            } else if (command.type === "addUser") {
              that.get("session.adminSession.users").pushObject(command.value);
            } else if (command.type === "updateUser") {
              that.get("session.adminSession.users").replace(command.value.pos, 1, [command.value.user]);
            }
          }
        }, '#admin');
      }
    }

  });
});