define('incus-vastaanota/services/countryselector', ['exports', 'ember', 'incus-vastaanota/config/environment'], function (exports, _ember, _incusVastaanotaConfigEnvironment) {

    /* CountrySelector keeps track of selected country */

    exports['default'] = _ember['default'].Service.extend({
        country: null,
        url: null,
        routes: null,

        init: function init() {
            this._super.apply(this, arguments);
            // Default country is set based on build variable
            var defaultcountry = _incusVastaanotaConfigEnvironment['default'].build === "laskumappi" ? "fi" : "sv";
            var defaultUrl = _incusVastaanotaConfigEnvironment['default'].build === "laskumappi" ? "laskumappi" : "fakturamappen";

            this.set("country", defaultcountry);
            this.set("url", defaultUrl);

            var def_routes = {};
            def_routes[defaultcountry] = [];

            this.set("routes", def_routes);

            _ember['default'].Logger.log("CountrySelector INIT to " + defaultcountry);
        },

        reroute: function reroute(current_route) {
            var c = this.get("country");
            var r = this.get("routes");

            // case of fi do no bypass (this makes assumption that normal route is compatible for fi)
            if (c === "fi") {
                return [current_route, false];
            }

            if (r[c] && r[c].indexOf(current_route) !== -1) {
                return [current_route, false];
            }

            var regex = new RegExp("\." + c + "$");
            var ischanged = false;

            if (!current_route.match(regex)) {
                current_route = current_route.replace(/\.index/, '');
                ischanged = true;

                return [current_route + "." + c, ischanged];
            }

            return [current_route, ischanged];
        },

        add_route_to_ignorelist: function add_route_to_ignorelist(route) {
            var c = this.get("country");
            var r = this.get("routes");

            if (!r[c]) {
                r[c] = [route];
            } else {
                r[c].pushObject(route);
            }
        }

    });
});