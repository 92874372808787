define("incus-vastaanota/pods/terms/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 164,
            "column": 0
          }
        },
        "moduleName": "incus-vastaanota/pods/terms/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "page-top");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "style", "max-width:800px;color:rgb(67, 67, 67);margin:auto;");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        dom.setAttribute(el2, "id", "palveluehdot");
        var el3 = dom.createTextNode("PALVELUEHDOT");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h3");
        dom.setAttribute(el2, "id", "2792011");
        var el3 = dom.createTextNode("27.9.2011");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n1. Sopimuksen osapuolet");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Tämän sopimuksen osapuolet ovat palvelun käyttöön ottava asiakas (jäljempänä “Asiakas”) ja Apix Messaging Oy, Y-tunnus 2332748-7 (jäljempänä “Apix”).");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n2. Sopimuksen kohde ja määritelmät");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Tällä sopimuksella Apix ja Asiakas sopivat Apix Sanomanvälityspalveluiden käytöstä. Apix Sanomanvälitypalveluilla tarkoitetaan tässä sopimuksessa sähköiseen tiedonsiirtoon liittyvien Apixin tuotteiden ja -palveluiden muodostamaa kokonaisuutta. Apixin Sanomanvälityspalvelut kattavat aineistojen muunnokset, reitittämisen ja jakelun täysin sähköisesti. Tarvittaessa aineistot lähetetään tulostettaviksi paperille. Apixin palveluihin sisältyy myös sähköinen arkistointi.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Tässä sopimuksessa tarkoitettu Apix Sanomanvälityspalvelu ja siihen liittyvät palvelutuotteet on tarkemmin kuvattu Apix Messaging Oy:n internetsivuilla osoitteessa www.apix.fi/palvelukuvaukset.html.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Apixilla on oikeus muuttaa, täydentää tai poistaa Apix Sanomanvälityspalveluiden ominaisuuksia. Mikäli palvelunominaisuuksiin tulevat muutokset, täydennykset tai poistot edellyttävät muutoksia Asiakkaan järjestelmissä, ilmoitetaan muutoksista Asiakkaalle hyvissä ajoin etukäteen.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Tällä sopimuksella ei luovuteta Asiakkaalle mitään oikeuksia mihinkään atk-ohjelmiin.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n3. Apix Sanomanvälityspalveluiden alkaminen ja päättyminen");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Asiakkaan oikeus käyttää Apix Sanomanvälityspalveluita päättyy tämän sopimuksen päättyessä.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n4. Palvelun käyttö");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Asiakas sitoutuu noudattamaan Apixin antamia Apix Sanomanvälityspalveluiden käyttöä koskevia ohjeita. Ohjeet löytyvät ajantasaisina osoitteesta www.apix.fi. Asiakas sitoutuu lukemaan ja noudattamaan näitä ohjeita ennen kuin ottaa palvelun käyttöön. Apix rekisteröi Apix Sanomanvälityspalveluiden hakemistoon Asiakkaan nimi- ja osoitetiedot sekä antaa käyttäjälle palvelun käytössä tarvittavat osoitteet, tunnukset ja salasanat. Apixilla on tarvittaessa oikeus muuttaa Asiakkaan käyttäjä- ja osoitetietoja ilmoitettuaan siitä Asiakkaalle hyvissä ajoin ennen muutosta. Asiakas vastaa tunnisteellaan tai salasanallaan tehdystä palvelun käytöstä.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Apix Sanomanvälityspalvelu on Asiakkaan käytettävissä 24 tuntia vuorokaudessa vuoden jokaisena päivänä lukuun ottamatta järjestelmän huollon tai ennalta arvaamattomien vikatilanteiden edellyttämiä käyttökeskeytyksiä. Huollon vaatimista katkoksista ilmoitetaan Asiakkaalle etukäteen ja ne pyritään ajoittamaan siten, että ne toteutetaan pääsääntöisesti normaalin työajan ulkopuolella. Apix pyrkii selvittämään ennalta arvaamattomat vikatilanteet mahdollisimman nopeasti.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n5. Maksut ja laskutusperusteet");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("5.1 Maksutapana etukäteismaksu");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Asiakas maksaa käyttämistään palveluista etukäteen verkkokaupasta hankittavilla krediiteillä. Krediittien saldoa ylläpidetään Apixin palvelimilla. Krediitiit maksetaan niiden hankintahetkellä ja krediittejä ei voi vaihtaa takaisin rahaksi. Apix varaa oikeuden mitätöidä hankitut krediitit mikäli niitä ei ole käytetty kahden vuoden kuluessa hankintahetkestä. Palveluiden ajantasainen hinnoittelu krediiteissä on saatavilla Apixin verkkosivuilta.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n5.2 Maksutapana lasku");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("5.2.1 Laskutusperusteet");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Apix Sanomanvälityspalveluista laskutetaan Asiakkaan palvelun käytön määrän perusteella kuukausittain jälkikäteen.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Käyttömaksujen perusteet ovat kirjattu erilliseen asiakaskohtaiseen hinnastoon.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("5.2.2 Maksuehdot");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Laskujen maksuehto on 14 päivää netto laskun päiväyksestä.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Maksut on ilmoitettu nettohintoina, joihin lisätään kulloinkin voimassa oleva arvonlisävero. Jos maksun suoritus viivästyy laskussa mainitusta eräpäivästä, Asiakas on velvollinen suorittamaan erääntymispäivän jälkeiseltä ajalta laskussa mainitun Suomen korkolain mukaisen viivästyskoron.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("5.2.3 Muutokset hintoihin ja laskutusperusteisiin");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Mikäli maksuissa tapahtuu korotuksia, neuvotellaan tästä kuukausi ennen korotusten voimaantuloa asiakkaan kanssa. Asiakkaalla on oikeus neuvottelun jälkeen irtisanoa sopimuksensa päättyväksi korotuksen tullessa voimaan.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n6. Oikeudet ja vastuut");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Apix vastaa siitä, että sen Asiakkaalle tuottama Apix Sanomanvälityspalvelu on palvelukuvauksen mukainen.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Asiakas on yksin vastuussa Apix Sanomanvälityspalveluiden puitteissa lähettämänsä tiedon tietosisällöstä. Tieto ei saa olla voimassa olevien lakien, asetusten, muiden viranomaismääräysten, hyvien tapojen tai yleisesti hyväksyttyjen menettelytapojen vastainen.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Asiakkaalla on oikeus saada korvaus välittömistä vahingoista, jotka aiheutuvat Apixin tuottamuksesta johtuvasta palvelun käyttökatkoksesta tai häiriöstä. Korvauksen määrä rajoittuu kuitenkin Asiakkaan yksittäisestä palveluominaisuudesta suorittamaan maksuun tai suoritettavaksi tulevaan maksuun kyseisellä laskutuskaudella. Asiakkaalla ei ole oikeutta saada korvausta Apixsta riippumattomista syistä aiheutuvista vahingoista. Apix ei korvaa Asiakkaalle aiheutuneita välillisiä vahinkoja (esimerkiksi tulon- tai ansion menetystä, pääoman korkotappioita, saamatta jäänyttä voittoa, liiketoiminnan keskeytymistä, sekä asiakkaan ja kolmannen väliseen sopimukseen perustuvia vahingonkorvauksia).");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Erityisesti todetaan, ettei Asiakkaalla ole oikeutta saada Apixilta korvausta palvelun käyttökatkoksesta tai häiriöstä, joka johtuu palvelun yhteydessä käytetyn teleoperaattorin tai muun palvelun tuottajan toiminnan keskeytyksistä tai häiriöistä.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n7. Salassapito");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Apix sekä Asiakas sitoutuvat pitämään luottamuksellisina toisiltaan saamansa aineistot ja tiedot, jotka on merkitty luottamukselliseksi tai jotka on sellaisiksi ymmärrettävä, sekä olemaan käyttämättä niitä muihin kuin sopimuksen mukaisiin tarkoituksiin.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Salassapitovelvollisuus ei kuitenkaan koske aineistoa ja tietoa:");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("a. joka on yleisesti saatavilla taikka muuten julkista tai");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("b. jonka Apix tai Asiakas on saanut kolmannelta osapuolelta ilman salassapitovelvollisuutta tai");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("c. joka oli Apixin tai Asiakkaan hallussa ilman niitä koskevaa salassapitovelvollisuutta ennen tietojen tai aineiston saamista Apixilta tai Asiakkaalta tai");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("d. jonka Apix tai Asiakas on itsenäisesti kehittänyt hyödyntämättä toiselta tämän sopimuksen osapuolelta saamaansa aineistoa tai tietoa.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Tämän sopimuksen osapuolen on välittömästi lopetettava toiselta osapuolelta saamansa luottamuksellisen aineiston ja tietojen käyttäminen sekä, ellei erikseen sovita aineiston hävittämisestä, palautettava kyseinen aineisto kaikkine kopioineen, kun sopimus päättyy tai kun sopimuksen osapuoli ei enää tarvitse kyseistä aineistoa, tai kyseisiä tietoja sopimuksen mukaiseen tarkoitukseen. Kummallakin tämän sopimuksen osapuolella on kuitenkin oikeus säilyttää lain tai viranomaisten määräysten edellyttämät kopiot.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Tämän sopimuksen kohtaan 6 liittyvät oikeudet ja velvoitteet jäävät voimaan sopimuksen päättymisen jälkeenkin.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n8. Ylivoimainen este");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Kumpikaan tämän sopimuksen osapuolista ei vastaa viivästyksistä tai vahingoista, jotka johtuvat hänen vaikutusmahdollisuuksiensa ulkopuolella olevasta esteestä, jota hänen ei kohtuudella voida edellyttää ottaneen huomioon sopimuksentekohetkellä ja jonka seurauksia hän ei myöskään kohtuudella olisi voinut välttää tai voittaa. Edellä mainitun kaltaisiksi esteiksi katsotaan mm. tulipalo, sota, kapina, pakko-otto, mellakka, takavarikko, lakko, sulku, boikotti ja muu työtaistelu, myös silloin kun tämän sopimuksen osapuoli on itse sen kohteena tai siihen osallisena.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Tämän sopimuksen osapuolen on viipymättä ilmoitettava ylivoimaisesta esteestä kirjallisesti toiselle sopimuksen osapuolelle, samoin kuin esteen lakkaamisesta.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n9. Yhteyshenkilöt");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Asiakas nimittää yhteyshenkilön asiakassuhteeseen. Apixin puolelta yhteyshenkilö ilmoitetaan servicedesk-palvelussa www.apix.fi.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n10. Asiakastietojen luovuttaminen");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Apixilla on oikeus käyttää asiakasrekisterin tietoja omassa markkinoinnissaan ellei tästä erityisesti ole muuta sovittu. Apixilla on oikeus luovuttaa Asiakkaiden nimet myös kolmansille osapuolille käytettäviksi yleisissä markkinatutkimuksissa tai niihin verrattavissa julkaisuissa. Asiakkaalla on oikeus kokonaan tai osittain kieltää Apixa käyttämästä tai luovuttamasta häntä koskevia tietoja edellä mainituin tavoin.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n11. Sovellettava laki ja erimielisyyksien ratkaiseminen");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Tähän sopimukseen sovelletaan Suomen lakia.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Sopimuksesta mahdollisesti aiheutuvat riidat pyritään pääsääntöisesti ratkaisemaan sopijapuolten välisin neuvotteluin. Tästä sopimuksesta aiheutuvat riidat ratkaistaan lopullisesti välimiesmenettelyssä Keskuskauppakamarin välimieslautakunnan sääntöjen mukaisesti yhden (1) välimiehen toimesta. Riita voidaan saattaa myös ratkaistavaksi sovinnollisesti Suomen Asianajajaliiton sovintomenettelysääntöjen mukaan tapahtuvassa sovintomenettelyssä, mikäli sopijapuolet siitä kirjallisesti sopivat.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Kuluttajaasiakkaalla on oikeus saattaa riita-asia vireille myös Suomessa sijaitsevan kotipaikkansa käräjäoikeudessa.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n12. Sopimuksen siirtäminen");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Apixilla on oikeus siirtää tämä kolmannelle ilmoitettuaan kirjallisesti siirrosta Asiakkaalle viimeistään kaksi (2) viikkoa ennen siirtoa. Asiakkaalla ei ole oikeutta siirtää sopimusta kolmannelle.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n13. Sopimuksen lisäykset ja muutokset");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Apixilla on oikeus muuttaa, täydentää tai poistaa palvelun ominaisuuksia. Apixilla on oikeus ilmoittaa tämän sopimuksen ehtojen muutoksesta julkaisemalla muutetut ehdot palveluntarjoajan www-sivustolla. Muutos tulee voimaan, kun se on julkaistu palveluntarjoajan www-sivustolla.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Mikäli palvelun ominaisuuksiin tulevat muutokset, täydennykset tai poistot edellyttävät muutoksia Asiakkaan järjestelmissä, ilmoitetaan muutoksista Asiakkaalle hyvissä ajoin etukäteen. Apix ei vastaa näistä muutoksista mahdollisesti Asiakkaalle aiheutuvista kustannuksista. Apixilla ei kuitenkaan ole yksipuolisesti oikeutta heikentää Asiakkaan tai tämän sopimuskumppanin saavuttamaa palvelutasoa edellä mainituilla muutoksilla, täydennyksillä tai poistoilla, elleivät heikennykset johdu Apixsta riippumattomista kolmannen osapuolen toimenpiteistä, joihin Apix ei voi vaikuttaa.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n14. Sopimuksen voimassaolo");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Sopimus on voimassa allekirjoituspäivästä alkaen toistaiseksi.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Asiakkaalla on oikeus irtisanoa sopimus päättymään kolmen (3) kuukauden irtisanomisajalla ilmoittamalla tästä kirjallisesti. Suoritettuja maksuja ei palauteta.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Asiakkaalla on lisäksi oikeus irtisanoa sopimus tämän sopimuksen kohdan 13 tarkoittamassa tapauksessa.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Apixilla on oikeus irtisanoa sopimus päättymään kolmen (3) kuukauden kuluttua kirjallisen ilmoituksen päiväyksestä.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Apixilla on oikeus irtisanomisaikaa noudattamatta purkaa sopimus tai estää palvelun käyttö seuraavissa tapauksissa:");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("a. Asiakas olennaisesti rikkoo näitä sopimusehtoja.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("b. Asiakkaan liittymää käytetään häiriön aiheuttamiseen Apix Sanomanvälityspalveluille tai muille käyttäjille.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("c. Asiakas on haettu konkurssiin tai hän on hakenut julkista haastetta velkojilleen tai Asiakas on muutoin todettu maksukyvyttömäksi.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Tämän sopimuksen sopijapuolilla ei ole sopimuksen purkautuessa oikeutta vaatia vahingonkorvausta yllä mainituista syistä johtuneen purkamisen johdosta.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n            \n\n            \n	    ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1, 1]), 0, 0);
        return morphs;
      },
      statements: [["inline", "t", ["common.terms"], [], ["loc", [null, [3, 13], [3, 33]]]]],
      locals: [],
      templates: []
    };
  })());
});