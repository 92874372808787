define("incus-vastaanota/pods/invoice/create/buy/controller", ["exports", "ember"], function (exports, _ember) {
    function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

    exports["default"] = _ember["default"].Controller.extend({
        apixclient: _ember["default"].inject.service(),
        countryselector: _ember["default"].inject.service(),

        actions: {
            initIframe: function initIframe() {
                var model = this.get("model");
                var apixclient = this.get("apixclient");
                var userData = this.get("session").get("user")[0];
                var url = this.get("countryselector.url");

                var that = this;
                iFrameResize({
                    log: false,
                    initCallback: function initCallback(iframe) {

                        var userinfo = [];
                        userinfo.push.apply(userinfo, _toConsumableArray(that.get("session").get("user")));
                        var locale = localStorage.getItem("locale");
                        if (url === "laskumappi" && locale === 'sv') {
                            locale = 'svfi';
                        }
                        userinfo.push({ locale: locale, app: "PurchaseInvoice" });
                        userinfo[0].entryPoint = url;
                        iframe.iFrameResizer.sendMessage(userinfo);
                    },
                    messageCallback: function messageCallback(msg) {
                        var command = JSON.parse(msg.message);
                        if (command.type === "path") {
                            console.log(command);
                            that.transitionToRoute(command.value, command.params.storageId, { queryParams: { storageKey: command.params.queryParams.storageKey } });
                        }
                    } }, '#laskunkirjoituskone');
            }
        }
    });
});